<template>
    <div class="empty" :class="{ small: hint }">
        <img class="empty-img" src="../../../../assets/images/newService/empty.png" alt="暂无数据">
        <div v-if="hint" class="hint">{{ hint }}</div>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    components: {},
    props: {
        hint: {
            type: String,
            default: ""
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {}
}
</script>

<style scoped lang="scss">
.empty {
    box-sizing: border-box;
    width: 100%;
    padding: 20% 20% 0;
    text-align: center;
    &.small {
        padding-top: 15%;
    }
    .empty-img {
        width: 100%;
    }
    .hint {
        margin-top: 30px;
        font-size: 14px;
        color: #606266;
    }
}
</style>
