<template>
    <div class="order-compensation">
        <div class="form-box">
            <el-form :model="ruleForm" label-width="68px" class="el-form">
                <el-form-item label="直赔原因" prop="reason">
                    <el-select v-model="ruleForm.reason" class="restriction" style="width: 100%;">
                        <el-option v-for="item in compensationTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="直赔金额" prop="money">
                    <el-input-number v-model="ruleForm.money" class="restriction money-input" :controls="false"></el-input-number>
                    <span class="hint">元，至消费者余额中</span>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="ruleForm.remark" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div class="apply">
                <el-button type="primary" class="apply-btn" :class="{ disabled: !btnDisabled }" :disabled="!btnDisabled" @click="applyCompensation">申请直赔</el-button>
            </div>
        </div>
        <div class="compensation-record">
            <div class="title">直赔记录</div>
            <div class="table-head">
                <div class="origin">直赔原因</div>
                <div class="money">直赔金额(￥)</div>
                <div class="remark">备注</div>
            </div>
            <div class="table-content">
                <div class="item" v-for="(item, index) in compensationRecord" :key="index">
                    <div class="origin" :title="item.label">{{ item.label }}</div>
                    <div class="money" >{{ item.money }}</div>
                    <div class="remark" :title="item.remark">{{ item.remark }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderCompensation',
    components: {},
    props: {
        robot: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            ruleForm: {
                reason: null,
                money: void 0,
                remark: '',
            },
            compensationTypeList: [
                { value: 1, label: "运费补偿" },
                { value: 2, label: "收货后少件补偿" },
                { value: 3, label: "商品质量问题补偿" },
                { value: 4, label: "商品描述差异补偿" },
                { value: 5, label: "其他类补偿" },
                { value: 6, label: "活动返现补偿" },
                { value: 7, label: "维修退换货瑕疵类补偿" },
                { value: 8, label: "运转不及时补偿" },
                { value: 9, label: "未完成送装服务补偿" },
            ],
        }
    },
    computed: {
        compensationRecord() {
            return this.robot.chatConfig.template.filter(item => item.stu_key_word instanceof Object && item.stu_key_word.score_operate == 2).map(item => {
                const target = this.compensationTypeList.find(typeItem => typeItem.value == item.stu_key_word.reason);
                return ({ ...item.stu_key_word, label: target ? target.label : ""  })
            });
        },
        btnDisabled() {
            return ["reason", "money"].every(key => this.ruleForm[key])
        }
    },
    methods: {
        applyCompensation() {
            const params = {
                ...this.ruleForm,
                score_operate: 2
            }
            // this.$emit("compensate", params)
            this.robot.chatConfig.sendMsg(params, params.score_operate);
        }
    }
}
</script>

<style scoped lang="scss">
.order-compensation {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    .form-box {
        box-sizing: border-box;
        width: 100%;
        padding: 30px 20px 30px;
        .money-input {
            width: 84px;
        }
        .hint {
            margin-left: 4px;
            color: #606266;
        }
        .apply {
            display: flex;
            justify-content: flex-end;
            .apply-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 30px;
                font-size: 12px;
                line-height: 0;
                color: #fff;
                background-color: #006AFF;
                border: none;
                &:hover {
                    background-color: #4c96ff;
                }
                &.disabled {
                    background-color: #96caff !important;
                }
            }
        }
    }
    .compensation-record {
        flex: 1;
        width: 100%;
        font-size: 14px;
        color: #606266;
        overflow: hidden;
        .title,
        .table-head,
        .table-content .item {
            box-sizing: border-box;
            padding: 10px 20px;
            display: flex;
            overflow: hidden;
        }
        .table-head,
        .table-content .item {
            .origin {
                flex: 13;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .money {
                flex: 8;
                text-align: center;
            }
            .remark {
                flex: 10;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .table-head {
            border: 1px solid #EAECF0;
            background-color: #F3F6F9;
        }
        .table-content {
            display: flex;
            flex-direction: column;
            .item {
                display: flex;
                border-bottom: 1px solid #EAECF0;
            }
        }
    }
}
::v-deep .restriction .el-input__inner {
    height: 36px;
}
::v-deep .el-form {
    .el-form-item {
        margin-bottom: 20px;
    }
}
</style>
