<template>
    <div class="operation-bar">
        <div class="tabs">
            <div v-for="(item, index) in tabList" :class="{ 'active-tab': index == tabIndex }" class="tab-item" :key="item" @click="tabIndex = index">{{ item }}</div>
        </div>
        <div class="tab-content">
            <Empty v-if="!robot && !answerStatus.beBing && tabIndex != 2" hint="暂无数据" />
            <ClientOrder v-else-if="tabIndex == 0" :robot="robot" />
            <OrderCompensation v-else-if="tabIndex == 1" :robot="robot" />
            <GoodsList v-else-if="tabIndex == 2" :goods-list="goodsList" :tab-index="tabIndex" :robot="robot" :answer-status="answerStatus" />
            <CouponCreate v-else-if="tabIndex == 3" :goods-list="goodsList" :robot="robot" />
        </div>
    </div>
</template>
<script>
import ClientOrder from "./operationBar/ClientOrder.vue"
import OrderCompensation from "./operationBar/OrderCompensation.vue"
import GoodsList from "./common/GoodsList.vue"
import CouponCreate from "./operationBar/CouponCreate.vue"
import Empty from "./common/Empty.vue"
import { getStudentGoods } from "../../../utils/apis"
export default {
    name: 'OperationBar',
    components: {
        Empty,
        ClientOrder,
        OrderCompensation,
        GoodsList,
        CouponCreate
    },
    props: {
        robot: {
            type: Object,
            default: () => null
        },
        answerStatus: {
            type: Object,
            default: () => {}
        }
    },
    data() {
      return {
        tabList: ["客户&订单", "订单直赔", "商品", "优惠券"],
        tabIndex: 0,
        //商品tab
        goodsList: []
      }
    },
    inject: ["isDev"],
    computed: {},
    methods: {
        init() {
            this.getGoodsList();
        },
        async getGoodsList() {
            const res = await getStudentGoods();
            this.goodsList = res.data;
        }
    },
    created() {
        this.init();
    }
}
</script>
<style scoped lang="scss">
$themeColor: #006AFF;
.operation-bar {
    box-sizing: border-box;
    /* flex: 36; */
    display: flex;
    flex-direction: column;
    width: 360px;
    min-width: 360px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EAECF0;
    overflow: hidden;
    .tabs {
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        border-bottom: 1px solid #E8E6EB;
        cursor: default;
        .tab-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            cursor: pointer;
            transition: color .3s;
            border-bottom-width: 2px;
            border-bottom-color: transparent;
            border-bottom-style: solid;
            transition: all .3s;
            &:hover {
                color: $themeColor;
            }
        }
        .active-tab {
            color: $themeColor;
            font-weight: 700;
            border-bottom-color: $themeColor;
        }
    }
    .tab-content {
        flex: 1;
        display: flex;
        overflow: hidden;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
