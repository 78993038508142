const emojiList = [
    {
        code: '#E-s31',
        src: require('./emoji/s31.png'),
    },
    {
        code: '#E-s32',
        src: require('./emoji/s32.png'),
    },
    {
        code: '#E-s33',
        src: require('./emoji/s33.png'),
    },
    {
        code: '#E-s34',
        src: require('./emoji/s34.png'),
    },
    {
        code: '#E-s35',
        src: require('./emoji/s35.png'),
    },
    {
        code: '#E-s36',
        src: require('./emoji/s36.png'),
    },
    {
        code: '#E-s37',
        src: require('./emoji/s37.png'),
    },
    {
        code: '#E-s38',
        src: require('./emoji/s38.png'),
    },
    {
        code: '#E-s39',
        src: require('./emoji/s39.png'),
    },
    {
        code: '#E-s40',
        src: require('./emoji/s40.png'),
    },
    {
        code: '#E-s01',
        src: require('./emoji/s01.png'),
    },
    {
        code: '#E-s02',
        src: require('./emoji/s02.png'),
    },
    {
        code: '#E-s03',
        src: require('./emoji/s03.png'),
    },
    {
        code: '#E-s04',
        src: require('./emoji/s04.png'),
    },
    {
        code: '#E-s05',
        src: require('./emoji/s05.png'),
    },
    {
        code: '#E-s06',
        src: require('./emoji/s06.png'),
    },
    {
        code: '#E-s07',
        src: require('./emoji/s07.png'),
    },
    {
        code: '#E-s08',
        src: require('./emoji/s08.png'),
    },
    {
        code: '#E-s09',
        src: require('./emoji/s09.png'),
    },
    {
        code: '#E-s10',
        src: require('./emoji/s10.png'),
    },
    {
        code: '#E-s11',
        src: require('./emoji/s11.png'),
    },
    {
        code: '#E-s12',
        src: require('./emoji/s12.png'),
    },
    {
        code: '#E-s13',
        src: require('./emoji/s13.png'),
    },
    {
        code: '#E-s14',
        src: require('./emoji/s14.png'),
    },
    {
        code: '#E-s15',
        src: require('./emoji/s15.png'),
    },
    {
        code: '#E-s16',
        src: require('./emoji/s16.png'),
    },
    {
        code: '#E-s17',
        src: require('./emoji/s17.png'),
    },
    {
        code: '#E-s18',
        src: require('./emoji/s18.png'),
    },
    {
        code: '#E-s19',
        src: require('./emoji/s19.png'),
    },
    {
        code: '#E-s20',
        src: require('./emoji/s20.png'),
    },
    {
        code: '#E-s21',
        src: require('./emoji/s21.png'),
    },
    {
        code: '#E-s22',
        src: require('./emoji/s22.png'),
    },
    {
        code: '#E-s23',
        src: require('./emoji/s23.png'),
    },
    {
        code: '#E-s24',
        src: require('./emoji/s24.png'),
    },
    {
        code: '#E-s25',
        src: require('./emoji/s25.png'),
    },
    {
        code: '#E-s26',
        src: require('./emoji/s26.png'),
    },
    {
        code: '#E-s27',
        src: require('./emoji/s27.png'),
    },
    {
        code: '#E-s28',
        src: require('./emoji/s28.png'),
    },
    {
        code: '#E-s29',
        src: require('./emoji/s29.png'),
    },
    {
        code: '#E-s30',
        src: require('./emoji/s30.png'),
    },
    {
        code: '#E-s41',
        src: require('./emoji/s41.png'),
    },
    {
        code: '#E-s42',
        src: require('./emoji/s42.png'),
    },
    {
        code: '#E-s43',
        src: require('./emoji/s43.png'),
    },
    {
        code: '#E-s44',
        src: require('./emoji/s44.png'),
    },
    {
        code: '#E-s45',
        src: require('./emoji/s45.png'),
    },
    {
        code: '#E-s46',
        src: require('./emoji/s46.png'),
    },
    {
        code: '#E-s47',
        src: require('./emoji/s47.png'),
    },
    {
        code: '#E-s48',
        src: require('./emoji/s48.png'),
    },
    {
        code: '#E-s49',
        src: require('./emoji/s49.png'),
    },
    {
        code: '#E-s50',
        src: require('./emoji/s50.png'),
    },
    {
        code: '#E-s51',
        src: require('./emoji/s51.png'),
    },
    {
        code: '#E-s52',
        src: require('./emoji/s52.png'),
    },
    {
        code: '#E-s53',
        src: require('./emoji/s53.png'),
    },
    {
        code: '#E-s54',
        src: require('./emoji/s54.png'),
    },
    {
        code: '#E-s55',
        src: require('./emoji/s55.png'),
    },
    {
        code: '#E-s56',
        src: require('./emoji/s56.png'),
    },
    {
        code: '#E-s57',
        src: require('./emoji/s57.png'),
    },
    {
        code: '#E-s58',
        src: require('./emoji/s58.png'),
    },
    {
        code: '#E-s59',
        src: require('./emoji/s59.png'),
    },
    {
        code: '#E-s60',
        src: require('./emoji/s60.png'),
    },
    {
        code: '#E-s61',
        src: require('./emoji/s61.png'),
    },
    {
        code: '#E-s62',
        src: require('./emoji/s62.png'),
    },
    {
        code: '#E-s63',
        src: require('./emoji/s63.png'),
    },
    {
        code: '#E-s64',
        src: require('./emoji/s64.png'),
    },
    {
        code: '#E-s65',
        src: require('./emoji/s65.png'),
    },
    {
        code: '#E-s66',
        src: require('./emoji/s66.png'),
    },
    {
        code: '#E-s67',
        src: require('./emoji/s67.png'),
    },
    {
        code: '#E-s68',
        src: require('./emoji/s68.png'),
    },
    {
        code: '#E-s69',
        src: require('./emoji/s69.png'),
    },
    {
        code: '#E-s70',
        src: require('./emoji/s70.png'),
    },
    {
        code: '#E-s71',
        src: require('./emoji/s71.png'),
    },
    {
        code: '#E-s72',
        src: require('./emoji/s72.png'),
    }
]

export default emojiList