<template>
    <div class="client-list-sidebar" >
        <div class="status-count">
            <template v-if="!answerStatus.beBing">
                <div class="unfinished">待接待: 0人</div>
                <div class="finished">已接待: {{finishCount}}人</div>
            </template>
            <template v-else>
                <div class="unfinished">待接待: {{ unfinishedCount }}人</div>
                <div class="finished">已接待: {{ finishCount }}人</div>
            </template>
        </div>
        <div class="robot-content" v-if="robotInfo && robotInfo.length > 0">
            <el-scrollbar style="height: 100%;">
                <div class="robot-list">
                    <div v-for="(group, groupIndex) in packList" :class="[groupIndex == 0 ? 'finished-list' : 'unfinished-list']">
                        <div v-for="item, index in group" class="robot-item" :class="{ current: robotInfo[robotIndex].id == item.id }" :key="item.id" @click="toggleRobot(item, index)">
                            <img :src="item.avatar" alt="" class="avatar" :class="{ finished: item.chatConfig.finished }">
                            <div class="info-box">
                                <div class="username">{{ item.name }}</div>
                                <div class="message-preview" v-if="item.chatConfig.template.length > 0">{{ item.chatConfig.template | getPreviewMsg }}</div>
                            </div>
                            <div class="corner-mark-box">
                                <!-- <div class="wait-time">等待时长：{{ waitTime }}S</div> -->
                                <div v-if="item.chatConfig.countdown" class="wait-time">倒计时：{{ item.chatConfig.countdown }}S</div>
                                <div class="message-hint" v-if="item.chatConfig.unread">1</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientListSidebar',
    components: {},
    props: {
        robotInfo: {
            type: Array,
            default: () => []
        },
        waitTime: {
            type: Number, 
            default: 0
        },
        robotIndex: {
            type: Number,
            default: 0
        },
        answerStatus: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
          defaultAvatarImg: require("../../../assets/images/newService/default_avatar.png")
        }
    },
    computed: {
        finishCount() {
            return this.robotInfo.filter(item => item.chatConfig.finished).length
        },
        unfinishedCount() {
            return this.robotInfo.length - this.finishCount;
        },
        packList() {
            const finish = [];
            const unFinish = [];
            this.robotInfo.forEach(item => {
                item.chatConfig.finished ? finish.push(item) : unFinish.push(item);
            });
            return [unFinish, finish];
        }
    },
    methods: {
        init() {},
        toggleRobot(item) {
            const index = this.robotInfo.findIndex(subItem => subItem.id == item.id);
            this.$emit("update:robotIndex", index);
        }
    },
    filters: {
        getPreviewMsg(question) {
            return question.slice(-1)[0].question;
        }
    },
    created() {}
}
</script>
<style scoped lang="scss">
.client-list-sidebar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 392px;
    min-width: 392px;
    height: 100%;
    padding-bottom: 20px;
    background-color: #fff;
    border-right: 1px solid #EAECF0;
    overflow: hidden;
    .status-count {
        display: flex;
        padding: 0 20px;
        margin: 28px 0 16px;
        font-size: 14px;
        color: #1E2226;
        .unfinished {
            margin-right: 40px;
        }
    }
    .robot-content {
        flex: 1;
        overflow: hidden;
        .robot-list {
            box-sizing: border-box;
            padding: 0 20px;
            
            .robot-item {
                box-sizing: border-box;
                display: flex;
                width: 100%;
                height: 64px;
                padding: 8px 20px 8px 10px;
                margin-bottom: 10px;
                font-size: 12px;
                color: #999;
                border: 1px solid #EAECF0;
                border-radius: 4px;
                background-color: #fff;
                transition: background-color .3s;
                user-select: none;
                cursor: pointer;
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    background-color: #f8f8f8;
                }
                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                }
                .finished {
                    filter: grayscale(1);
                }
                .info-box {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin: 0 10px;
                    overflow: hidden;
                    .username {
                        width: 100%;
                        font-size: 14px;
                        color: #1E2226;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .message-preview {
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .corner-mark-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    .message-hint {
                        width: 20px;
                        height: 20px;
                        font-size: 12px;
                        line-height: 20px;
                        text-align: center;
                        color: #fff;
                        background-color: #FF6F6F;
                        border: 1px solid #E14646;
                        border-radius: 50%;
                    }
                }
            }
            .finished-list {
                display: flex;
                flex-direction: column-reverse;
                .robot-item:last-child {
                    margin-bottom: 10px;
                }
            }
            .current {
                background-color: #EFEFEF !important;
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
