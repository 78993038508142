<template>
    <div class="coupon-create">
        <div class="form-box">
            <el-form :model="ruleForm" label-width="76px" ref="formRule" :rules="rules" >
                <el-form-item label="类型" prop="coupon_id">
                    <el-radio-group v-model="ruleForm.coupon_id">
                        <el-radio :label="1">商品优惠券</el-radio>
                        <el-radio :label="2">店铺优惠券</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="优惠">
                    <div class="money-container" style="display: flex; margin-bottom: 10px;">
                        满
                        <el-form-item prop="price_man">
                            <el-input-number class="money-input" v-model="ruleForm.price_man" :controls="false"></el-input-number>元
                        </el-form-item>
                        减
                        <el-form-item prop="price_jian">
                            <el-input-number  class="money-input" v-model="ruleForm.price_jian" :controls="false"></el-input-number>元
                        </el-form-item>
                    </div>
                    <div class="hint">折扣率为0%</div>
                </el-form-item>
                <el-form-item label="有效期" prop="validity_time">
                    <el-radio-group v-model="ruleForm.validity_time">
                        <el-radio :label="1" style="margin-bottom: 20px;">当日23:59:59前使用有效</el-radio>
                        <el-radio :label="2">次日23:59:59前使用有效</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="选择商品" prop="good_id">
                    <el-select v-model="ruleForm.good_id" style="width: 100%;">
                        <el-option v-for="(item) in goodsList" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="select-goods">
            <Goods v-if="selectGoods" :goods="selectGoods" />
            <Empty v-else hint="暂未选择商品" />
        </div>
        <div class="bottom-operation">
            <el-button class="send-btn" type="primary" @click="sendCoupon">发送</el-button>
        </div>
    </div>
</template>

<script>
import Goods from "../common/GoodsItem.vue"
import Empty from "../common/Empty.vue"
export default {
    name: 'CouponCreate',
    components: {
        Goods,
        Empty
    },
    props: {
        robot: {
            type: Object,
            default: () => {}
        },
        goodsList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            ruleForm: {
                coupon_id: 1,
                good_id: null,
                price_man: void 0,
                price_jian: void 0,
                validity_time: 1,
            },
            rules: {
                coupon_id: [{ required: true, message: "请选择优惠券类型", trigger: "change" }],
                good_id: [{ required: true, message: "请选择商品", trigger: "change" }],
                price_man: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
                price_jian: [{ required: true, message: "必填项不能为空", trigger: "blur" }]
            }
        }
    },
    computed: {
        selectGoods() {
            return this.goodsList.find(item => item.goods_id == this.ruleForm.good_id)
        }
    },
    methods: {
        sendCoupon() {
            this.$refs.formRule.validate(valid => {
                if(!valid) return void 0;
                const params = Object.assign({}, this.ruleForm, { score_operate: 4 });
                this.robot && this.robot.chatConfig.sendMsg(params, params.score_operate);
            })
        }
    }
}
</script>

<style scoped lang="scss">
.coupon-create {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    .form-box {
        box-sizing: border-box;
        width: 100%;
        padding: 30px 20px;
        border-bottom: 1px solid #E8E6EB;
        .hint {
            font-size: 12px;
            color: #8C8C8C;
        }
    }
    .select-goods {
        box-sizing: border-box;
        flex: 1;
        padding: 16px 20px 0;
    }
    .bottom-operation {
        padding: 10px 0;
        text-align: center;
        .send-btn {
            width: 119px;
            background-color: #006AFF;
            border: none;
            &:hover {
                background-color: #3d8eff;
            }
        }
    }
}
::v-deep .money-input {
    margin: 0 6px;
    width: 75px;
}
::v-deep .el-form-item__label {
    padding-right: 20px;
}
::v-deep .el-form-item__label::before {
    display: none;
}
</style>
