<template>
    <div class="contextmenu" @click.stop>
        <div class="menu-list" v-if="visible" tabindex="1" ref="menu" @blur="closeMenu" >
            <div class="menu-item" v-if="moveUp" @click="offsetUp">上移</div>
            <div class="menu-item" @click="edit">修改</div>
            <div class="menu-item" @click="$emit('del')">删除</div>
            <div class="menu-item" v-if="moveDown" @click="offsetDown">下移</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contextmenu',
    components: {},
    props: {
        index: {
            type: Number,
            default: 0
        },
        moveUp: {
            type: Boolean,
            default: false
        },
        moveDown: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {}
    },
    watch: {
        visible(newVal, oldVal) {
            newVal && this.$nextTick(() => this.$refs.menu && this.$refs.menu.focus())
        }
    },
    computed: {},
    methods: {
        closeMenu() {
            this.$emit("update:visible", false);
        },
        edit() {
            this.closeMenu();
            this.$emit('edit');
        },
        offsetUp() {
            this.$emit('up', { order: 1, id: this.item.id });
            this.closeMenu();
        },
        offsetDown() {
            this.$emit('down', { order: 0, id: this.item.id });
            this.closeMenu();
        } 
    }
}
</script>

<style scoped lang="scss">
.contextmenu {
    float: right;
    .menu-list {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 100px;
        font-size: 14px;
        color: #333;
        background-color: #f0edf1;
        z-index: 9;
        border-radius: 4px;
        overflow: hidden;
        outline: none;
        .menu-item {
            display: flex;
            align-items: center;
            height: 35px !important;
            padding: 0 10px;
            transition: all .3s;
            cursor: pointer;
            &:hover {
                color: #fff;
                background-color: #006aff;
            }
        }
    }
}
</style>
