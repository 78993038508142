<template>
    <div class="goods-list">
        <div class="input-box" v-if="tabIndex == 2">
            <el-input class="custom-input" v-model="keyword" placeholder="搜索商品关键词"></el-input>
        </div>
        <div class="content">
            <el-scrollbar style="height: 100%;">
                <div class="tab-containers">
                    <goods-item v-for="(item) in viewList" :key="item.goods_id" :goods="item">
                        <el-button v-if="tabIndex == 2 && answerStatus.beBing" class="btn-send" type="text" style="padding: 0;" @click="sendGoods(item)">发送</el-button>
                        <el-button v-if="tabIndex == 3" class="btn-delete" type="text" style="padding: 0;" @click="sendGoods(item)">删除</el-button>
                    </goods-item>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import GoodsItem from "./GoodsItem.vue"
export default {
    name: 'GoodsTab',
    components: {
        GoodsItem
    },
    props: {
        robot: {
            type: Object,
            default: () => {}
        },
        goodsList: {
            type: Array,
            default: () => []
        },
        tabIndex: {
            type: Number,
            default: 0
        },
        answerStatus: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            keyword: "",
            timer: void 0
        }
    },
    computed: {
        viewList() {
            if(!this.keyword.trim()) return this.goodsList;
            // return this.goodsList.filter(item => item.goods_name.includes(this.keyword.trim())).map(item => ({ ...item, goods_promotion_price: item.setting_sale }));
            return this.goodsList.filter(item => item.goods_name.includes(this.keyword.trim()));
        }
    },
    methods: {
        sendGoods(item) {
            const params = {
                goodInfo: item,
                good_id: item.goods_id,
                score_operate: 3
            }
            this.robot.chatConfig.sendMsg(params, params.score_operate);
        }
    }
}
</script>
<style scoped lang="scss">
$themeColor: #006AFF;

.goods-list {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .input-box {
        padding: 10px 20px 0;
        margin-bottom: 20px;
        .custom-input {
            width: 100%;
            ::v-deep .el-input__inner {
                height: 30px;
            }
        }
    }
    
    .content {
        flex: 1;
        padding-bottom: 20px;
        overflow: hidden;
        .tab-containers {
            padding: 0 20px;
            .btn-send {
                color: $themeColor !important;
            }
            .btn-delete {
                color: #EF2626;
            }
        }
    }
}

</style>
