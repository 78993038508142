<template>
    <div class="client-order" v-if="robot">
        <div class="state-tab" v-if="robot.is_have">
            <div class="all">全部(1)</div>
            <div class="finished">已完成(1)</div>
        </div>
        <el-scrollbar style="flex: 1;">
            <div class="main">
                <div class="target-goods" v-if="!robot.is_have">
                    <div class="hint">
                        <span class="username">{{ robot.name }}</span>
                        正在浏览: 
                    </div>
                    <Goods :goods="robot.good_info" />
                </div>
                <div class="coupon-list">
                    <Coupon class="coupon-item" v-for="(item, index) in couponList" :key="index" :coupon="item" @sendCoupon="sendCoupon" />
                </div>
                <div class="check-order" v-if="robot.is_have">
                    <div class="order-info">
                        <div class="base-info">
                            <div class="item order-no">订单号: {{ robot.order_sn }}</div>
                            <div class="item buy-time">下单时间: {{ robot.order_time }}</div>
                            <div class="item status">状态: 已付款</div>
                        </div>
                        <div class="main-info">
                            <div class="purchaser-info">
                                <div class="goods-price">
                                    <span>商品总价: </span>
                                    <span class="price" style="color: #EC3535;">{{ robot.good_info.goods_promotion_price }}</span>
                                </div>
                                <div class="shipping-address">收货信息: {{ robot.recipients }}, {{ robot.contact }}, {{ robot.address }}</div>
                            </div>
                            <Goods :goods="robot.good_info" />
                        </div>
                    </div>
                    <div class="check-info">
                        <el-button class="check-btn" @click="checkOrder">核对订单</el-button>
                        <div class="purchaser-info">
                            <div class="item purchaser">收货人: {{ robot.recipients }}</div>
                            <div class="item phone">联系电话: {{ robot.contact }}</div>
                            <div class="item address">地址: {{ robot.address }}</div>
                        </div>
                        <div class="parameter-info">
                            <div class="buy-count">购买数量: 1</div>
                            <div class="actual-payment">
                                实付款: <span style="color: #EC3535;">{{ robot.good_info.goods_promotion_price }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Goods from "../common/GoodsItem.vue"
import Coupon from "../common/Coupon.vue"
export default {
    name: 'ClientOrder',
    components: {
        Goods,
        Coupon
    },
    props: {
        robot: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {}
    },
    inject: ["validateQuestionType"],
    computed: {
        couponList() {
            if(!this.robot) return [];
            const target = this.robot.question.find(item => item.coupon_info);
            return target ? target.coupon_info.map(item => ({ ...item, coupon_id: target.coupon_id })) : []
        }
    },
    methods: {
        checkOrder() {
            const { recipients, contact, address, order_sn, good_info } = this.robot;
            const params = {
                recipients,
                contact,
                address,
                order_sn,
                goodInfo: good_info,
                score_operate: 5
            }
            
            this.robot.chatConfig.sendMsg(params, params.score_operate);
        },
        sendCoupon(item) {
            const couponInfo = {};
            Object.keys(item).forEach(key => key != "coupon" && this.$set(couponInfo, key, item[key]));
            const params = {
                coupon_id: item.coupon_id,
                coupon_info: couponInfo,
                score_operate: 1
            }
            this.robot && this.robot.chatConfig.sendMsg(params, params.score_operate);
        }
    }
}
</script>

<style scoped lang="scss">
.client-order {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    overflow: hidden;
    .state-tab {
        display: flex;
        padding: 0 20px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #006AFF;
        font-weight: bold;
        .finished {
            margin-left: 30px;
            color: #606266;
            font-weight: normal;
        }
    }
    .main {
        box-sizing: border-box;
        padding: 0 10px;
        .target-goods {
            font-size: 14px;
            margin-bottom: 20px;
            .hint {
                margin-bottom: 10px;
                color: #8C8C8C;
                .username {
                    color: #1E2226;
                }
            }
        }
        .coupon-list {
            width: 100%;
            .coupon-item {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .check-order {
            width: 100%;
            font-size: 14px;
            color: #1E2226;
            .order-info {
                width: 100%;
                border: 1px solid #E6E7EB;
                border-radius: 4px;
                margin-bottom: 10px;
                .base-info {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 16px;
                    border-bottom: 1px solid #E6E7EB;
                    background-color: #F8F8FA;
                    .item {
                        margin-bottom: 6px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .main-info {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 10px;
                    .purchaser-info {
                        margin-bottom: 10px;
                        .goods-price {
                            margin-bottom: 6px;
                        }
                    }
                }
            }
            .check-info {
                box-sizing: border-box;
                width: 100%;
                padding: 16px;
                border: 1px solid #E6E7EB;
                border-radius: 4px;
                .check-btn {
                    float: right;
                    width: 80px;
                    height: 28px;
                    padding: 0;
                    padding-left: 1px;
                    color: #006AFF;
                    text-align: center;
                    border: 1px solid #006AFF;
                }
                .purchaser-info {
                    width: 100%;
                    margin-bottom: 16px;
                    .item {
                        margin-bottom: 6px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .parameter-info {
                    display: flex;
                    font-size: 12px;
                    color: #8C8C8C;
                    .buy-count {
                        margin-right: 14px;
                    }
                }
            }
        }
    }
}
::v-deep el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
