import { render, staticRenderFns } from "./Contextmenu.vue?vue&type=template&id=778aa520&scoped=true"
import script from "./Contextmenu.vue?vue&type=script&lang=js"
export * from "./Contextmenu.vue?vue&type=script&lang=js"
import style0 from "./Contextmenu.vue?vue&type=style&index=0&id=778aa520&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778aa520",
  null
  
)

export default component.exports